import styled, { keyframes } from 'styled-components';
import { themeGet } from 'styled-system';

export const HeaderV1Wrap = styled.header`
  background-image: url(${props => props.backdrop});
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  figure {
    max-width: calc(100% - 300px);
    min-width: 70%;
    width: auto;
    height: 60%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    color: #fff;
    font-size: 2rem;
    max-width: 500px;
    position: absolute;
    text-align: left;
    left: -5%;

    @media screen and (min-width: 600px) {
      font-size: 5rem;
      left: -50px;
      bottom: -80px;
    }
  }

  figcaption {
    text-align: center;
    margin-top: 1em;
    font-style: italic;
    color: ${themeGet('color.4')};
  }
`;

export const HeaderV2Wrap = styled.header`
  width: 100%;
  height: 100vh;
  background: ${themeGet('color.4')} center/cover
    url(${props => props.background}) no-repeat;
  background-blend-mode: multiply;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  h1 {
    max-width: 80%;
    font-size: 2rem;
    color: #ff5368;

    @media screen and (min-width: 600px) {
      font-size: 8rem;
    }
  }

  p {
    max-width: 80%;
    font-size: 1rem;
    font-style: italic;

    @media screen and (min-width: 600px) {
      font-size: 1.3rem;
    }
  }
`;

export const HeaderV3Wrap = styled.header`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;
  margin-bottom: 25px;
  overflow: hidden;

  picture {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .parallax-outer {
    position: relative;
    z-index: 3;
    width: 80%;
    @media only screen and (max-width: 480px) {
      width: 90%;
    }
  }

  .box {
    width: 100%;
    box-sizing: border-box;
    border: 8px solid #ff5368;
    min-height: 40vh;
    display: flex;
    flex-flow: row wrap;
    @media only screen and (max-width: 1200px) {
      min-height: 32vh;
    }
    @media only screen and (max-width: 801px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 480px) {
      border-width: 6px;
    }
    > div {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px;
      @media only screen and (max-width: 480px) {
        padding: 15px 15px 25px;
      }

      &.meta {
        max-width: 315px;
        width: 100%;
        color: ${themeGet('color.8')};
        border-right: 8px solid #ff5368;
        @media only screen and (max-width: 801px) {
          max-width: 100%;
          border-right: 0;
          position: relative;
          text-align: center;

          &::before {
            content: '';
            width: 110px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 110px / 2);
            height: 2px;
            border-radius: 6px;
            background-color: #fff;
          }
        }
      }
      &.title {
        flex: 1;
        color: ${themeGet('color.8')};
      }
    }
  }

  blockquote {
    font-size: 1.3rem;
    font-weight: 400;
    font-style: italic;
    @media only screen and (max-width: 801px) {
      font-size: 1.6rem;
    }
  }
`;

const ObjectTwiggle = keyframes`
  0%, 100% {
    transform: translateX(5%) translateY(-5%) rotate(-330deg) scale(1);
  }
  50% {
    transform: translateX(0%) translateY(0%) rotate(-327deg) scale(1.1);
  }
`;

export const HeaderV5Wrap = styled.header`
  display: flex;
  flex-flow: row wrap;
  height: 450px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 5%;
  background-color: ${themeGet('color.7')};

  .object {
    position: absolute;
    width: 30vw;
    height: 30vw;
    z-index: 1;
    transform: rotate(-330deg);
    background-color: ${themeGet('color.1')};
    border-radius: 5px;
    animation: 5s ${ObjectTwiggle} ease-in infinite;
    &:nth-child(1) {
      left: -10%;
      top: -25%;
    }
    &:nth-child(2) {
      bottom: -50%;
      left: 40%;
      width: 20vw;
      height: 20vw;
      animation-delay: 2s;
    }
    &:nth-child(3) {
      right: -5%;
      top: -15%;
      animation-delay: 1s;
    }
  }

  h1 {
    font-size: 1.2rem;
    font-weight: 300;
    /* width: 70%; */
    color: ${themeGet('color.4')};
    position: relative;
    z-index: 2;
    line-height: 1.2em;
    font-style: italic;
    text-align: center;

    @media screen and (min-width: 600px) {
      font-size: 3rem;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -70%;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      height: 1px;
      background-color: ${themeGet('color.4')};
    }
  }
`;
