import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import {
  HeaderV1Wrap,
  HeaderV2Wrap,
  HeaderV3Wrap,
  HeaderV5Wrap,
} from './Header.style';

const HeaderV1 = ({ rubriek, title, meta, backdrop, bg }) => (
  <HeaderV1Wrap
    backdrop={
      backdrop &&
      backdrop.localFile &&
      backdrop.localFile.childImageSharp.fluid.src
    }
  >
    {rubriek && <h4 className="rubriek">{rubriek}</h4>}
    {bg && bg.localFile && (
      <figure>
        <picture>
          <source
            srcSet={bg.localFile.childImageSharp.fluid.srcSetWebp}
            type="image/webp"
          />
          <source
            srcSet={bg.localFile.childImageSharp.fluid.srcSet}
            type="image/jpeg"
          />
          <img src={bg.localFile.childImageSharp.fluid.src} alt={bg.alt_text} />
        </picture>
        <figcaption className="meta">{meta}</figcaption>
        <h1>{title}</h1>
      </figure>
    )}
  </HeaderV1Wrap>
);

const HeaderV2 = ({ bg, meta, title }) => (
  <HeaderV2Wrap background={bg}>
    <h1>{title}</h1>
    <p>{meta}</p>
  </HeaderV2Wrap>
);

const HeaderV3 = ({ bg, meta, title }) => (
  <HeaderV3Wrap>
    {bg && bg.localFile && (
      <picture>
        <source
          srcSet={bg.localFile.childImageSharp.fluid.srcSetWebp}
          type="image/webp"
        />
        <source
          srcSet={bg.localFile.childImageSharp.fluid.srcSet}
          type="image/jpeg"
        />
        <img src={bg.localFile.childImageSharp.fluid.src} alt={bg.alt_text} />
      </picture>
    )}
    <Parallax y={[50, -50]}>
      <div className="box">
        {meta && (
          <div className="meta">
            <blockquote>"{meta}"</blockquote>
          </div>
        )}

        <div className="title">
          <h1>{title}</h1>
        </div>
      </div>
    </Parallax>
  </HeaderV3Wrap>
);

const HeaderV5 = ({ title }) => (
  <HeaderV5Wrap className="page-header">
    <div className="object" />
    <div className="object" />
    <div className="object" />
    <h1>{title}</h1>
  </HeaderV5Wrap>
);

const Header = ({ title, variant, meta, rubrieken, bg, backdrop }) => {
  console.log(bg);

  return (
    <>
      {variant === '1' && (
        <HeaderV1
          title={title}
          rubriek={rubrieken}
          meta={meta}
          bg={bg}
          backdrop={backdrop}
        />
      )}
      {variant === '2' && <HeaderV2 bg={bg} meta={meta} title={title} />}
      {variant === '3' && <HeaderV3 bg={bg} meta={meta} title={title} />}
      {variant === '5' && <HeaderV5 title={title} />}
    </>
  );
};

export default Header;
