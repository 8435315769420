import styled from 'styled-components';

const StoryWrap = styled.div`
  width: 80%;
  max-width: 800px;
  display: block;
  margin: auto;
`;

export default StoryWrap;
