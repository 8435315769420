import React from 'react';
import { ParagraphWrapper } from './Paragraph.style';
import StoryWrap from '../StoryWrap/StoryWrap.style';

const Paragraph = ({ background, text }) => (
  <ParagraphWrapper>
    {background && background.localFile && (
      <picture>
        <source
          srcSet={background.localFile.childImageSharp.fluid.srcSetWebp}
          type="image/webp"
        />
        <source
          srcSet={background.localFile.childImageSharp.fluid.srcSet}
          type="image/jpeg"
        />
        <img
          src={background.localFile.childImageSharp.fluid.src}
          alt={background.alt_text}
        />
      </picture>
    )}
    <StoryWrap dangerouslySetInnerHTML={{ __html: text }}></StoryWrap>
  </ParagraphWrapper>
);

export default Paragraph;
