import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header/Header';
import Paragraph from '../components/Paragraph/Paragraph';
import Layout from '../layout';

const AfdelingenTemplate = ({ data: { wordpressWpAfdelingen } }) => (
  <Layout>
    <Header
      variant="2"
      title={wordpressWpAfdelingen.title}
      bg={
        wordpressWpAfdelingen.acf.header_image &&
        wordpressWpAfdelingen.acf.header_image.localFile &&
        wordpressWpAfdelingen.acf.header_image.localFile.childImageSharp.fluid
          .src
      }
    />
    <Paragraph text={wordpressWpAfdelingen.acf.contactgegevens} />
  </Layout>
);

export const afdelingQuery = graphql`
  query($id: String!) {
    wordpressWpAfdelingen(id: { eq: $id }) {
      id
      title
      acf {
        contactgegevens
        rsvp_email
        calendar_items {
          date_time
          description
          title
        }
        header_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1400) {
                src
                srcSet
                srcSetWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default AfdelingenTemplate;
