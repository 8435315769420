import styled from 'styled-components';

export const ParagraphWrapper = styled.div`
  background-size: cover;
  background-position: center;
  line-height: 1.7em;
  text-align: justify;
  position: relative;
  max-width: 100%;
  overflow: hidden;

  > picture {
    position: absolute;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
    }
  }

  img {
    max-width: 100%;
    height: auto;

    &.alignleft {
      float: left;
      margin: 5%;
      clear: both;
    }

    &.alignright {
      float: right;
      margin: 5%;
      clear: both;
    }
  }
`;
